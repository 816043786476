import './App.css';
import React from "react";
import GlobalStyles from './styles/GlobalStyles';

import LandingPage from "./LandingPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import Credits from "pages/Credits";
import AboutUs from "pages/AboutUs";
import ContactUs from "pages/ContactUs";
import Booking from "pages/Booking";
import ReactPixel from 'react-facebook-pixel';
import BathroomCleaningVideos from "./pages/BathroomCleaningVideos";
import Videos from 'pages/Videos';

ReactPixel.init('640671024671551');
ReactPixel.pageView();
export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
      <>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/bathroom-cleaning-videos" element={<BathroomCleaningVideos />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/videos" element={<Videos />} />
            <Route path="/credits" element={<Credits />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/contact" element={<ContactUs />} />
              {/* <Route path="/booking" element={<Booking />} /> */}
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Router>
      </>
  );
}
