import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import TeamCardGrid from "../components/team.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-8.svg";

import {Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import {SectionHeading, Subheading as SubheadingBase} from "../components/misc/Headings";
import {SectionDescription} from "../components/misc/Typography";
import ReactPixel from "react-facebook-pixel";

const HighlightedText = tw.span`text-primary-500`;
// const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;


const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

// const DecoratorQuoteL = styled(QuoteStart)`
//   ${tw`pointer-events-none -z-20 absolute left-0 top-0 ml-8 lg:ml-24 h-24 w-24 opacity-15 transform -translate-x-2/3 text-primary-500`}
// `;
//
// const DecoratorQuoteR = styled(QuoteEnd)`
//   ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 mr-8 lg:mr-24 h-24 w-24 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
// `;

export default ({roundedHeaderButton=true}) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewAboutPage', {
            content_name: 'About Page Visited',
            content_category: 'Page views',
        });
    }, []);
    
  return (
    <AnimationRevealPage disabled={true}>
        <Header roundedHeaderButton={roundedHeaderButton} />
        <Container>
            <ContentWithPaddingXl>
                <Subheading>OUR VISION</Subheading>
                {/*<Heading>Some Vision <HighlightedText>Statement.</HighlightedText></Heading>*/}
                <Heading><HighlightedText>Revolutionize</HighlightedText> home maintenance, <HighlightedText>Redefining</HighlightedText> the way people <HighlightedText>Experience</HighlightedText> and <HighlightedText>Care</HighlightedText> for their living spaces.</Heading>
                {/*<Description>Some description, if at all it is required</Description>*/}
            </ContentWithPaddingXl>
            {/*<DecoratorQuoteL/>*/}
            {/*<DecoratorQuoteR/>*/}
            <DecoratorBlob1/>
        </Container>
        <Container>
            <ContentWithPaddingXl>
                <Subheading>OUR MISSION</Subheading>
                {/*<Heading>Some Mission <HighlightedText>Statement.</HighlightedText></Heading>*/}
                <Heading>Provide <HighlightedText>Seamless</HighlightedText> and <HighlightedText>Exceptional</HighlightedText> home maintenance services, driven by commitment to <HighlightedText>Quality, Convenience,</HighlightedText> and <HighlightedText>Customer satisfaction.</HighlightedText></Heading>
                {/*<Description>Some description, if at all it is required</Description>*/}
            </ContentWithPaddingXl>
            <DecoratorBlob2 />
        </Container>

      <TeamCardGrid
          heading={
              <>
                  Meet The <HighlightedText>Team.</HighlightedText>
              </>
          }
        subheading={"OUR TEAM"}
        description={null}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
