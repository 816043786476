import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase} from "./misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { ReactComponent as CheckMark } from "images/check-mark.svg";
import { ReactComponent as FacebookIcon } from "../images/social/facebook.svg";
import { ReactComponent as TwitterIcon } from "../images/social/twitter.svg";
import { ReactComponent as InstagramIcon } from "../images/social/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../images/social/linkedin.svg";
import { ReactComponent as CallIcon } from "../images/social/call.svg";
import supabase from "../db/supabase";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const SubmitButton = styled(PrimaryButtonBase)(props => [
  tw`text-lg inline-block mx-auto md:mx-0 mt-8 mb-8`,
  props.buttonRounded && tw`rounded-full`,
  props.type === 'submit' && tw`bg-primary-500 text-gray-100`
]);

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`text-lg inline-block mx-auto md:mx-0 mt-4 mb-4`,
  props.buttonRounded && tw`rounded-full`
]);

const SvgContainer = styled.div`
  ${tw`inline-block mt-4 mr-4`}
  svg {
    ${tw`w-32 h-32`}

    @media (max-width: 768px) {
    ${tw`w-24 h-24`} // Adjust dimensions for mobile screens
  }
  }
`;

const SocialLinksContainer = tw.div`mt-4 text-center sm:(text-left mx-auto)`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-primary-500 text-gray-100 hover:bg-primary-700 transition duration-300 mr-4`}
  svg {
    ${tw`w-8 h-8`}
  }
`;

export default ({
  subheading = "CONTACT US",
  heading = <>Feel free to <span tw="text-primary-500">get in touch.</span></>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [submitting, setSubmitting] = useState(false);

  const createFeedback = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // console.log(formData.get("phone"));
    // console.log(formData.get("name"));
    // console.log(formData.get("subject"));
    // console.log(formData.get("message"));

    if(formData.get("phone") !== '' && formData.get("name") !== '' && formData.get("subject") !== ''
        && formData.get("message") !== '') {
      writeToSupabase(formData.get("phone"), formData.get("name"), formData.get("subject"), formData.get("message"));
    }
  }

  const writeToSupabase = async (phone, name, subject, message) => {

    try {
      setSubmitting(true);
      const { data, error } = await supabase
          .from('feedback')
          .insert([
            {
              user_name: name,
              contact_number: phone,
              subject: subject,
              message: message,
              source: 'web'
            }
          ]);
      if (error) {
        throw error;
      }
    setSubmitting(false);
      console.log('Data written successfully');
    setFormSubmitted(true);
    } catch (error) {
      console.error('Error writing data: ', error.message);
    }
  };

  const [formSubmitted, setFormSubmitted] = useState(false);

  const showFormAgain = () => {
    setFormSubmitted(false);
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {formSubmitted ? (
                // Show a different view after form submission
                <div>
                  <SvgContainer>
                    <CheckMark/>
                  </SvgContainer>
                  <Description>Thank you! We will look into this on priority :)</Description>
                  <PrimaryButton buttonRounded={true} onClick={showFormAgain}>
                    Okay
                  </PrimaryButton>
                </div>
            ) : (
            <Form onSubmit={createFeedback}>
              <Input type="phone" name="phone" placeholder="Phone Number" required={true} />
              <Input type="text" name="name" placeholder="Full Name" required={true} />
              <Input type="text" name="subject" placeholder="Subject" required={true} />
              <Textarea name="message" placeholder="Your Message Here" required={true} />

              <SubmitButton buttonRounded={true} disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
              </SubmitButton>
            </Form>
            )}
          </TextContent>
          <Subheading>SOCIALS</Subheading>
          <SocialLinksContainer>
            <SocialLink href="tel:+919090102038" target="_blank">
              <CallIcon />
            </SocialLink>
            <SocialLink href="https://www.facebook.com/livease.in" target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://instagram.com/livease_in" target="_blank">
              <InstagramIcon />
            </SocialLink>
            {/*<SocialLink href="" target="_blank">*/}
            {/*  <TwitterIcon />*/}
            {/*</SocialLink>*/}
            <SocialLink href="https://www.linkedin.com/company/livease-in/" target="_blank">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
