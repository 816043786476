import React, { forwardRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-8.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  // .link {
  //   ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
  //   .icon {
  //     ${tw`ml-2 w-4`}
  //   }
  // }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

const DecoratorBlob3 = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
                    cards = [
                        {
                            imageSrc: "",
                            title: "Secure",
                            description: "We strictly only deal with vendors that provide top notch security.",
                            url: "https://timerse.com"
                        },
                        {
                            imageSrc: "",
                            title: "24/7 Support",
                            description: "Lorem ipsum donor amet siti ceali placeholder text",
                            url: "https://google.com"
                        },
                        {
                            imageSrc: "",
                            title: "Customizable",
                            description: "Lorem ipsum donor amet siti ceali placeholder text",
                            url: "https://reddit.com"
                        }
                    ],
                    linkText = "Learn More",
                    heading = "",
                    subheading = "",
                    description = "",
                    textOnLeft = true,
                    features = null,
                    iconRoundedFull = true,
                    iconFilled = true,
                    iconContainerCss = null,
                    imageContainerCss = null,
                    imageCss = null
                }) => {
    /*
     * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  4) url - the url that the card should goto on click
     */

    const defaultFeatures = [
        {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We have the best professional marketing people across the globe just to work with you.",
            iconContainerCss: tw`bg-teal-300 text-teal-800`
        },
        {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-red-300 text-red-800`
        },
        {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We have the best professional marketing people across the globe just to work with you.",
            iconContainerCss: tw`bg-teal-300 text-teal-800`
        },
        {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-red-300 text-red-800`
        },
    ];

    if (!features) features = defaultFeatures;

    return (
        <Container>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                {heading && <Heading>{heading}</Heading>}
                {description && <Description>{description}</Description>}
                <ThreeColumnContainer>
                    {cards.map((card, i) => (
                        <Column key={i}>
                            <Card>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                                <span className="title">{card.title}</span>
                                <p className="description">{card.description}</p>
                                {/*              {linkText && (*/}
                                {/*                  <span className="link">*/}
                                {/*  <span>{linkText}</span>*/}
                                {/*  <ArrowRightIcon className="icon" />*/}
                                {/*</span>*/}
                                {/*              )}*/}
                            </Card>
                        </Column>
                    ))}
                </ThreeColumnContainer>

            </ContentWithPaddingXl>
            <DecoratorBlob1 />
            <DecoratorBlob3 />
        </Container>
    );
};
