import React, {useEffect, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import ReactPixel from "react-facebook-pixel";
import {SectionTitle} from "components/misc/Headings.js";
import ArrowDown from "../images/arrow_down.gif";

const Heading = tw(SectionTitle)``;

const VideoContainer = tw.div`flex flex-wrap`;
const VideoRow = tw.div`flex flex-wrap w-full`;
const VideoWrapper = tw.div`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2`;
const SocialLink = styled.a`
  ${tw`cursor-pointer fixed right-0 bottom-0 mb-4 inline-block p-2 rounded-full bg-white text-gray-100 hover:bg-white transition duration-300 mr-4 shadow-raised border border-black`}
  img {
    ${tw`w-6 h-6`}
  }
`;

export default ({ headingText = "", roundedHeaderButton=true }) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewVideosPage', {
            content_name: 'Videos Page Visited',
            content_category: 'Page views',
        });
    }, []);

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleScrollToNextVideo = () => {
    const nextVideoIndex = (currentVideoIndex + 1) % 8; // TODO: Change based on video count
    const nextVideoElement = document.getElementById(`video-${nextVideoIndex}`);
    if (nextVideoElement) {
      nextVideoElement.scrollIntoView({ behavior: 'smooth' });
    }
    setCurrentVideoIndex(nextVideoIndex);
  };

    return (
        <AnimationRevealPage disabled={true}>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <br></br>
            {/* <Heading id="sofa-cleaning">Sofa Cleaning</Heading>
            <VideoContainer>
                <VideoRow>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/L6klmHOloi0" title="Premium sofa cleaning in kolhapur city by LivEase, Download the app now and save more with AMC" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                </VideoRow>
            </VideoContainer>
            <Heading id="pest-control">Pest Control</Heading>
            <VideoContainer>
                <VideoRow>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/WA8vZMR6E84" title="Herbal pest control solutions in kolhapur city by LivEase, Call 9090102038 to book the service" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    </VideoWrapper>
                </VideoRow>
            </VideoContainer> */}
            {/* <Heading id="bathroom-cleaning">Bathroom Cleaning</Heading> */}
            <VideoContainer>
                <VideoRow>
                    <VideoWrapper id="video-1">
                    <iframe width="329" height="585" src="https://www.youtube.com/embed/WapWBlALsk8" title="Premium Sofa cleaning in Kolhapur City, #sofacleaning #Home cleaning #sofa #vacuumcleaner" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    </VideoWrapper>
                    <VideoWrapper id="video-2">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/yaW2P3w2ETU" title="Livease services... a comfortable way to make your bathroom shine #Livease #kolhapur #transformation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper id="video-3">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/nfkkED_pzAY" title="Deep chair cleaning in kolhapur, call 9090-1020-30 #sofa #chaircleaning #Homecleaning #facts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>                    
                    </VideoWrapper>
                    <VideoWrapper id="video-4">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/fWQvWoFY88k" title="Amazing Bathroom cleaning in मुक्त सैनिक वसाहत, कोल्हापूर by LivEase Company." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper id="video-5">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/dowFFWC3oKQ" title="Premium bathroom cleaning in Kolhapur city by LivEase...  kolhapur #transformation #bathroomcleaning" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper id="video-6">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/j6kOMi8XHLM" title="Elevate your Bathing experience with LivEase" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper id="video-7">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/MyURspL8IBU" title="Get premium Bathroom cleaning Experience now in Kolhapur city..." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper id="video-8">
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/HZosxyZJekg" title="Premium Bathroom cleaning services in Kolhapur city by LivEase. Call us on 9090-1020-38" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                </VideoRow>
            </VideoContainer>
            {/* <Heading id="water-tank-cleaning">Water Tank Cleaning</Heading> */}
            <Footer />
            <SocialLink onClick={handleScrollToNextVideo}>
            <img src={ArrowDown} alt="Next" />
            </SocialLink>
        </AnimationRevealPage>
    );
};
