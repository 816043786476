import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import BookingForm from "../components/booking.js";
import { ReactComponent as WhatsAppIcon } from "../images/social/whatsapp.svg";
import Supabase from "../db/supabase.js";

import styled from "styled-components";
import ReactPixel from 'react-facebook-pixel';
const SocialLink = styled.a`
  ${tw`cursor-pointer fixed right-0 bottom-0 mb-4 inline-block p-2 rounded-full bg-green-500 text-gray-100 hover:bg-green-700 transition duration-300 mr-4 shadow-raised`}
  svg {
    ${tw`w-16 h-16`}
  }
`;

const writeToSupabase = async () => {
    try {
        const { data, error } = await Supabase
            .from('your_table_name')
            .insert([
                {
                    column1: 'value1',
                    column2: 'value2',
                    // ... add other columns and values here
                }
            ]);
        if (error) {
            throw error;
        }
        console.log('Data written successfully:', data);
    } catch (error) {
        console.error('Error writing data:', error.message);
    }
};

export default ({roundedHeaderButton=true}) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewBookingPage', {
            content_name: 'Booking Page Visited',
            content_category: 'Page views',
        });
    }, []);

  return (
    <AnimationRevealPage disabled={true}>
        <Header roundedHeaderButton={roundedHeaderButton} />
      <BookingForm
          heading={
              <>Create a <span tw="text-primary-500">Booking.</span></>
          }
          description={<>Currently serving in <span tw="text-primary-500">Kolhapur.</span></>}
      />
      <Footer />
        <SocialLink href="https://api.whatsapp.com/send?phone=+919090102038&text=Hi,+I+am+looking+for+home+service">
            <WhatsAppIcon />
        </SocialLink>
        <SocialLink href="https://api.whatsapp.com/send?phone=+919090102038&text=Hi,+I+am+looking+for+home+service">
            <WhatsAppIcon />
        </SocialLink>
    </AnimationRevealPage>
  );
};
