import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/header";
import Footer from "../components/footer.js";
import { SectionHeading } from "../components/misc/Headings";
import ReactPixel from "react-facebook-pixel";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Credits", roundedHeaderButton=true }) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewCreditsPage', {
            content_name: 'Credits Page Visited',
            content_category: 'Page views',
        });
    }, []);

    return (
        <AnimationRevealPage disabled={true}>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>
                        <p>Last updated: September 21, 2023</p>

                        <p>
                            This page describes the the free resources that have made it possible to make this site the way it is.
                            We have tried not to miss out on any credits, but if by any chance we missed it, feel free to get in touch with us so that we can get it added.
                        </p>

                        <p>
                            Again, thanks a lot to all these awesome folks, you guys are doing an amazing job.
                        </p>

                        <h2>Page Illustrations</h2>
                        <p>
                            <stong>Hero Illustration: </stong> <a href="https://www.freepik.com/free-vector/household-renovation-professions-illustrated_9906703.htm#query=home%20service&position=18&from_view=search&track=ais">Image by Freepik</a>
                            <strong>Booking page illustation: </strong> <a href="https://www.freepik.com/free-vector/cleaners-with-cleaning-products-housekeeping-service_4887688.htm#query=home%20service&position=3&from_view=search&track=ais">Image by jemastock on Freepik</a>
                        </p>

                        <h2>Services Illustrations</h2>
                        <ul>
                            <li>
                                <strong>Plumbing:</strong> <a href="https://www.flaticon.com/free-icons/plumber" title="plumber icons">Plumber icons created by Peerapak Takpho - Flaticon</a>
                            </li>
                            <li>
                                <strong>Cleaning:</strong> <a href="https://www.flaticon.com/free-icons/cleaning" title="cleaning icons">Cleaning icons created by photo3idea_studio - Flaticon</a>
                            </li>
                            <li>
                                <strong>Electrician:</strong> <a href="https://www.flaticon.com/free-icons/electrician" title="electrician icons">Electrician icons created by Iconic Panda - Flaticon</a>
                            </li>
                            <li>
                                <strong>Carpentry:</strong> <a href="https://www.flaticon.com/free-icons/toolbox" title="toolbox icons">Toolbox icons created by Freepik - Flaticon</a>
                            </li>
                            <li>
                                <strong>Painting:</strong> <a href="https://www.flaticon.com/free-icons/painter" title="painter icons">Painter icons created by monkik - Flaticon</a>
                            </li>
                            <li>
                                <strong>Appliances:</strong> <a href="https://www.flaticon.com/free-icons/air-conditioning" title="air conditioning icons">Air conditioning icons created by surang - Flaticon</a>
                            </li>
                            <li>
                                <strong>Water tank: </strong> <a href="https://www.flaticon.com/free-icons/water-tank" title="water tank icons">Water tank icons created by Freepik - Flaticon</a>
                            </li>
                            <li>
                                <strong>Pest Control: </strong> <a href="https://www.flaticon.com/free-icons/pest-control" title="pest control icons">Pest control icons created by Icongeek26 - Flaticon</a>
                            </li>
                            <li>
                                <strong>And More: </strong> <a href="https://www.flaticon.com/free-icons/service" title="service icons">Service icons created by Freepik - Flaticon</a>
                            </li>
                            
                        </ul>

                        <h2>Social Icons</h2>
                        <p>
                            <a href="https://www.svgrepo.com/">SVG Repo</a>
                        </p>

                        <h2>Check Icon</h2>
                        <p>
                            <a href="https://icons8.com/icon/63262/checkmark">Check</a> icon by <a href="https://icons8.com">Icons8</a>
                        </p>

                        <h2>Other Icons</h2>
                        <p>
                            <a href="https://feathericons.com/">Feather Icons</a>
                        </p>

                        <h2>Website Template</h2>
                        <p>Last but not the least, without it the site would have just been imaginary. A huge thanks to the creator of the template that we used for this site.</p>
                        <p>
                            <a href="https://owaiskhan.me/">Check out the Creator</a>
                        </p>
                        <p>
                            <a href="https://treact.owaiskhan.me/">Check out the Template</a>
                        </p>

                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};
