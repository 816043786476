import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import ContactUsForm from "../components/contact.js";
import { ReactComponent as WhatsAppIcon } from "../images/social/whatsapp.svg";

import styled from "styled-components";
import ReactPixel from "react-facebook-pixel";

const SocialLink = styled.a`
  ${tw`cursor-pointer fixed right-0 bottom-0 mb-4 inline-block p-2 rounded-full bg-green-500 text-gray-100 hover:bg-green-700 transition duration-300 mr-4 shadow-raised`}
  svg {
    ${tw`w-16 h-16`}
  }
`;
export default ({roundedHeaderButton=true}) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewContactPage', {
            content_name: 'Contact Page Visited',
            content_category: 'Page views',
        });
    }, []);

  return (
    <AnimationRevealPage disabled={true}>
        <Header roundedHeaderButton={roundedHeaderButton} />
      <ContactUsForm
          description={"Booking issues, doubts, reviews, suggestions or anything, we are open to it all."}
      />
      <Footer />
        <SocialLink href="https://api.whatsapp.com/send?phone=+919090102038&text=Hi,+I+am+looking+for+home+service">
            <WhatsAppIcon />
        </SocialLink>
    </AnimationRevealPage>
  );
};
