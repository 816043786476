import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "./header.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../images/hero.svg";
import GooglePlay from "../images/get-it-on-google-play.png";
import {SectionHeading} from "./misc/Headings";
import {PrimaryButton as PrimaryButtonBase} from "./misc/Buttons";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

// const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Cities = tw.p`my-2 lg:my-5 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const PrimaryButton = styled(PrimaryButtonBase)(props => [
    tw`text-lg inline-block mx-auto md:mx-0`,
    props.buttonRounded && tw`rounded-full`
]);

const ImageContainer = styled.div`
  ${tw`w-full h-full flex justify-center items-center`}

  ${tw`lg:(justify-start)`}
`;

const ImageLink = styled.a`
  ${tw`block`}
`;

const Image = styled.img`
  ${tw`w-48 h-auto`}
`;

export default ({ roundedHeaderButton }) => {
    return (
        <>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <Container>
                <TwoColumn>
                    <LeftColumn>
                        <Heading>
                            Home Services, <span tw="text-primary-500">Simplified.</span>
                        </Heading>
                        <Paragraph>
                            LivEase is the simplest way to get your home in order with the right professionals for all your needs.
                        </Paragraph>
                        {/* <PrimaryButton buttonRounded={true} as="a" href="/booking">
                            Book Now
                        </PrimaryButton> */}

                        <ImageContainer>
                            <ImageLink href="https://play.google.com/store/apps/details?id=com.apps.harsh.livease_mobile&utm_source=web_hero">
                                <Image src={GooglePlay} alt="Get it on Google Play" />
                            </ImageLink>
                        </ImageContainer>

                    </LeftColumn>
                    <RightColumn>
                        <IllustrationContainer>
                            <img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
                        </IllustrationContainer>
                    </RightColumn>
                </TwoColumn>
                <DecoratorBlob1 />
            </Container>
        </>
    );
};
