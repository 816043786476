import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import ReactPixel from "react-facebook-pixel";


const VideoContainer = tw.div`flex flex-wrap`;
const VideoRow = tw.div`flex flex-wrap w-full`;
const VideoWrapper = tw.div`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-2`;

export default ({ headingText = "", roundedHeaderButton=true }) => {

    useEffect(() => {
        // Track a custom event when the component mounts
        ReactPixel.track('ViewBathroomCleaningVideosPage', {
            content_name: 'Bathroom Cleaning Videos Page Visited',
            content_category: 'Page views',
        });
    }, []);

    return (
        <AnimationRevealPage disabled={true}>
            <Header roundedHeaderButton={roundedHeaderButton} />
            <br></br>
            <VideoContainer>
                <VideoRow>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/fWQvWoFY88k" title="Amazing Bathroom cleaning in मुक्त सैनिक वसाहत, कोल्हापूर by LivEase Company." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/dowFFWC3oKQ" title="Premium bathroom cleaning in Kolhapur city by LivEase...  kolhapur #transformation #bathroomcleaning" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/j6kOMi8XHLM" title="Elevate your Bathing experience with LivEase" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/MyURspL8IBU" title="Get premium Bathroom cleaning Experience now in Kolhapur city..." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/HZosxyZJekg" title="Premium Bathroom cleaning services in Kolhapur city by LivEase. Call us on 9090-1020-38" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </VideoWrapper>
                    <VideoWrapper>
                        <iframe width="329" height="585" src="https://www.youtube.com/embed/yaW2P3w2ETU" title="Livease services... a comfortable way to make your bathroom shine #Livease #kolhapur #transformation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </VideoWrapper>
                </VideoRow>
            </VideoContainer>

            <Footer />
        </AnimationRevealPage>
    );
};
