import React, {useState, useEffect} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase} from "./misc/Buttons.js";
import BookingIllustration from "images/booking.svg";
import { ReactComponent as CheckMark } from "images/check-mark.svg";
import supabase from "../db/supabase";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import ReactPixel from "react-facebook-pixel";
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-7/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-5/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const Select = tw.select`
  mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium 
  transition duration-300 hocus:border-primary-500
`;

const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

// const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;
const SubmitButton = styled(PrimaryButtonBase)(props => [
  tw`text-lg inline-block mx-auto md:mx-0 mt-8 mb-8`,
  props.buttonRounded && tw`rounded-full`,
  props.type === 'submit' && tw`bg-primary-500 text-gray-100`
]);

const PrimaryButton = styled(PrimaryButtonBase)(props => [
  tw`text-lg inline-block mx-auto md:mx-0 mt-4 mb-4`,
  props.buttonRounded && tw`rounded-full`
]);

const SvgContainer = styled.div`
  ${tw`inline-block mt-4 mr-4`}
  svg {
    ${tw`w-32 h-32`}

    @media (max-width: 768px) {
    ${tw`w-24 h-24`} // Adjust dimensions for mobile screens
  }
  }
`;

const UpdateNotice = tw(Column)`w-full flex-auto mt-4 sm:mt-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

export default ({
  subheading = "BOOK A SERVICE",
  heading = <>Feel free to <span tw="text-primary-500">get in touch.</span></>,
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Book",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [submitting, setSubmitting] = useState(false);

  const createBooking = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // console.log(formData.get("phone"));
    // console.log(formData.get("name"));
    // console.log(formData.get("address"));
    // console.log(formData.get("landmark"));
    // console.log(formData.get("pin"));
    // console.log(formData.get("description"));

    // console.log(typeof selectedOption);
    // console.log(selectedOption);

    if(formData.get("phone") !== '' && formData.get("name") !== '' && formData.get("address") !== ''
        && formData.get("pin") !== '' && formData.get("category") !== '' && formData.get("description") !== '') {
      writeToSupabase(formData.get("phone"), formData.get("name"), formData.get("address"), formData.get("landmark"),
          formData.get("pin"), formData.get("category"), formData.get("description"));
      ReactPixel.trackCustom('CreateBooking');
    }
  }

  const writeToSupabase = async (phone, name, address, landmark, pin, category, description) => {
    try {
      // console.log(phone);
      setSubmitting(true);
      const { data, error } = await supabase
          .from('services')
          .insert([
            {
              user_name: name,
              contact_number: phone,
              address: address,
              landmark: landmark,
              pin_code: pin,
              category_id: selectedOption,
              description: description,
              status: 'created',
              source: 'web'
            }
          ]);
      if (error) {
        throw error;
      }
      setSubmitting(false);
      console.log('Data written successfully');
      setFormSubmitted(true);
    } catch (error) {
      console.error('Error writing data: ', error.message);
    }
  };

  const [options, setOptions] = useState([]);

  const fetchOptions = async () => {
    try {
      const { data, error } = await supabase.from('category').select('id, category_name');
      if (error) {
        throw error;
      }
      return data;
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    const fetchOptionsData = async () => {
      const data = await fetchOptions();
      setOptions(data);
    };

    fetchOptionsData();
  }, []);

  const [selectedOption, setSelectedOption] = useState(1);
  const handleSelectChange = (event) => {
    setSelectedOption(+event.target.value);
  };

  const [formSubmitted, setFormSubmitted] = useState(false);

  const showFormAgain = () => {
    setFormSubmitted(false);
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={BookingIllustration} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}

            {formSubmitted ? (
                // Show a different view after form submission
                <div>
                  <SvgContainer>
                    <CheckMark/>
                  </SvgContainer>
                  <Description>Service request submitted successfully! We will get in touch shortly :)</Description>
                  <PrimaryButton buttonRounded={true} onClick={showFormAgain}>
                    Okay
                  </PrimaryButton>
                </div>
            ) : (
            <Form onSubmit={createBooking}>
              <Input type="phone" name="phone" placeholder="Phone Number" required={true}/>
              <Input type="text" name="name" placeholder="Full Name" required={true} />
              <Textarea name="address" placeholder="Complete Address" required={true} />
              <Input type="text" name="landmark" placeholder="Landmark" />
              <Input type="number" name="pin" placeholder="PIN Code" required={true} />
              {/*<Input type="text" name="category" placeholder="Service Category" required={true} />*/}
              <Select onChange={handleSelectChange}>
                {options.map((option) => (
                    <option key={option.id} value={+option.id}>
                      {option.category_name}
                    </option>
                ))}
              </Select>
              <Textarea name="description" placeholder="Service Description" required={true} />
              <UpdateNotice>
                <UpdateNoticeIcon />
                Visiting fees applicable, if you cancel after the Partner has reached for the service.
              </UpdateNotice>
              <SubmitButton buttonRounded={true} disabled={submitting}>
                {submitting ? 'Submitting...' : 'Submit'}
                {/*{submitButtonText}*/}
              </SubmitButton>
            </Form>
            )}
            {/*{submitting && (<div className="spinner border-t-4 border-blue-500 rounded-full w-12 h-12"></div>)}*/}
              </TextContent>
        </TextColumn>

      </TwoColumn>
    </Container>
  );
};
