import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../images/asset.svg";

import { ReactComponent as FacebookIcon } from "../images/social/facebook.svg";
import { ReactComponent as TwitterIcon } from "../images/social/twitter.svg";
import { ReactComponent as InstagramIcon } from "../images/social/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../images/social/linkedin.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`
// const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const LogoTextWithFont = styled(LogoText)`
  font-family: 'Kanit', sans-serif;
`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 lg:mr-16`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`
const CopyrightNotice = tw.div``
const CompanyInfo = tw.div``

const Divider = tw.div`my-8 border-b-2 border-gray-800`

export default () => {
  return (
    <Container>
      <Content>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoTextWithFont>LivEase Technologies</LogoTextWithFont>
          </LogoContainer>
          <CompanyDescription>
            {/*LivEase is a technology company focused on making people's lives easier by providing all the services they need at their fingertips.*/}
            LivEase is focused on elevating lives of people living in lower-tier cities by providing all the home services they need at their fingertips.
          </CompanyDescription>
          {/*<SocialLinksContainer>*/}
          {/*  <SocialLink href="https://facebook.com">*/}
          {/*    <FacebookIcon />*/}
          {/*  </SocialLink>*/}
          {/*  <SocialLink href="https://twitter.com">*/}
          {/*    <TwitterIcon />*/}
          {/*  </SocialLink>*/}
          {/*  <SocialLink href="https://youtube.com">*/}
          {/*    <YoutubeIcon />*/}
          {/*  </SocialLink>*/}
          {/*</SocialLinksContainer>*/}
        </WideColumn>

        {/*<Column>*/}
        {/*  <ColumnHeading>About</ColumnHeading>*/}
        {/*  <LinkList>*/}
        {/*    <LinkListItem>*/}
        {/*      <Link href="#">Vision</Link>*/}
        {/*    </LinkListItem>*/}
        {/*    <LinkListItem>*/}
        {/*      <Link href="#">Mission</Link>*/}
        {/*    </LinkListItem>*/}
        {/*    <LinkListItem>*/}
        {/*      <Link href="#">Team</Link>*/}
        {/*    </LinkListItem>*/}
        {/*  </LinkList>*/}
        {/*</Column>*/}
        <Column>
          <ColumnHeading>LEGAL</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/terms-of-service">Terms of Service</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="/credits">Credits</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>CONTACT</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link>+91 9090 1020 38</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="mailto:hello@livease.co.in" target="_blank">hello@livease.co.in</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>NOW IN</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link>Kolhapur</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <Divider/>
      <CopyrightAndCompanyInfoRow>
        <CopyrightNotice>&copy; Copyright 2024, LivEase Technologies</CopyrightNotice>
        {/*<CompanyInfo>An Internet Company.</CompanyInfo>*/}
        <SocialLinksContainer>
          <SocialLink href="https://www.facebook.com/livease.co.in" target="_blank">
            <FacebookIcon />
          </SocialLink>
          <SocialLink href="https://instagram.com/livease_in" target="_blank">
            <InstagramIcon />
          </SocialLink>
          {/*<SocialLink href="" target="_blank">*/}
          {/*  <TwitterIcon />*/}
          {/*</SocialLink>*/}
          <SocialLink href="https://www.linkedin.com/company/livease-in/" target="_blank">
            <LinkedInIcon />
          </SocialLink>
        </SocialLinksContainer>
      </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
