import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "./misc/Layouts";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "./misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";

import Rating from "../images/rating.png";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-40 h-40 rounded-full`;
const Quote = tw.blockquote`-mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;
// TODO: add highlights to important review words
export default ({
                    subheading = "TESTIMONIALS",
                    heading = "Customer's Review",
                    testimonials = [
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                            quote:
                                "आम्ही LivEase कडून Plumbing ची सर्व्हिस घेतली. खूप दिवसांपासून प्लंबिंग चे काम तसेच पडून होते. अगदी वेळेवर व योग्य दरात काम पूर्ण झाले.",
                            customerName: "Rohit Koli, Nagala Park"
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                            quote:
                                "It was a best service we got at affordable price, we will definitely try the service on Monthly basis.",
                            customerName: "Sunil Padaki, Mukt Sainik Vasahat"
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
                            quote:
                                "I recently took bathroom service from LivEase. Service was good and they did a fabulous job, extremely happy after seeing my bathroom condition. I like to appreciate their efforts.",
                            customerName: "Samir Kanitkar, Ruikar colony, Tarabai park"
                        }
                    ]
                }) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                <Testimonials>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialContainer key={index}>
                            <Testimonial>
                                <Image src={Rating} />
                                <Quote>"{testimonial.quote}"</Quote>
                                <CustomerName>- {testimonial.customerName}</CustomerName>
                            </Testimonial>
                        </TestimonialContainer>
                    ))}
                </Testimonials>
            </ContentWithPaddingXl>

            <DecoratorBlob1 />
        </Container>
    );
};
