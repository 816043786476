import React, { forwardRef } from "react";
import styled, {keyframes} from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
// const ThreeColumnContainer = styled.div`
//   ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
// `;

// ORIGINAL
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}

  @media (max-width: 640px) {
    justify-content: center; // Centering the columns horizontally on small screens
  }
`;
const Column = styled.div`
  ${tw`lg:w-1/5 sm:w-1/4 max-w-xs`}
`;

// const ThreeColumnContainer = styled.div`
//   ${tw`mt-10 flex flex-wrap justify-center max-w-screen-lg mx-auto`}
// `;

// const Column = styled.div`
//   ${tw`w-full sm:w-1/2 md:w-1/3 lg:w-1/5 max-w-xs`}
// `;

// const Column = styled.div`
//   ${tw`w-full sm:w-1/2 md:w-1/3 lg:w-1/5 max-w-xs mx-auto`}
// `;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;


// Animated 
// const scrollFromRightToLeft = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// const CardContainer = styled.div`
//   ${tw`flex flex-row overflow-hidden`}
//   animation: ${scrollFromRightToLeft} 20s linear infinite;
// `;

// const Card = styled.a`
//   ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded`}
//   // position: relative;
//   // animation: ${scrollFromRightToLeft} 10s linear infinite;

//   .imageContainer {
//     ${tw`text-center rounded-full p-4 bg-gray-100`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .title {
//     ${tw`mt-4 font-bold text-xl leading-none`}
//   }
// `;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
                    cards = [
                        {
                            imageSrc: "",
                            title: "Secure",
                            description: "We strictly only deal with vendors that provide top notch security.",
                            url: "https://timerse.com"
                        },
                        {
                            imageSrc: "",
                            title: "24/7 Support",
                            description: "Lorem ipsum donor amet siti ceali placeholder text",
                            url: "https://google.com"
                        },
                        {
                            imageSrc: "",
                            title: "Customizable",
                            description: "Lorem ipsum donor amet siti ceali placeholder text",
                            url: "https://reddit.com"
                        }
                    ],
                    linkText = "Learn More",
                    heading = "",
                    subheading = "",
                    description = "",
                    imageContainerCss = null,
                    imageCss = null
                }) => {
    /*
     * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
     *  1) imageSrc - the image shown at the top of the card
     *  2) title - the title of the card
     *  3) description - the description of the card
     *  4) url - the url that the card should goto on click
     */
    return (
        <Container>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                {heading && <Heading>{heading}</Heading>}
                {description && <Description>{description}</Description>}
                <ThreeColumnContainer>
                {/* <CardContainer> */}
                    {cards.map((card, i) => (
                        <Column key={i}>
                            <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                                <span className="title">{card.title}</span>
                                {/* <p className="description">{card.description}</p> */}
                            </Card>
                        </Column>
                    ))}
                    {/* </CardContainer> */}
                </ThreeColumnContainer>
            </ContentWithPaddingXl>
            <DecoratorBlob />
        </Container>
    );
};
