import React, {useEffect} from "react";

import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "./components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {Subheading} from "./components/misc/Headings";
import { ReactComponent as WhatsAppIcon } from "./images/social/whatsapp.svg";

import Footer from "./components/footer";
import Faqs from "./components/faqs";
import Hero from "./components/hero";
import Stats from "components/stats.js";
import DownloadApp from "./components/downloadApp";
import Testimonials from "components/testimonials.js";

import Services from "./components/services";
import Principles from "./components/principles";

import Plumber from "images/services/plumber.png";
import Cleaner from "images/services/household.png";
import Electrician from "images/services/electrician.png";
import Carpenter from "images/services/carpenter.png";
import Painter from "images/services/painter.png";
import Equipment from "images/services/maintenance.png";
import Fabrication from "images/services/welding.png";
import Gas from "images/services/geyser.png";
import Plastering from "images/services/plastering.png";
import Drainage from "images/services/drainage.png";
import WaterTank from "images/services/water-tank.png";
import PestControl from "images/services/pest-control.png";
import AndMore from "images/services/services.png";

import PricingIcon from "./images/principles/pricing.svg";
import VerifiedIcon from "./images/principles/verified.svg";
import ExpertIcon from "./images/principles/expert.svg";
import FlexibleIcon from "./images/principles/flexible.svg";
import SupportIcon from "./images/principles/support.svg";



/* Hero */
const HighlightedText = tw.span`text-primary-500`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;


const SocialLink = styled.a`
  ${tw`cursor-pointer fixed right-0 bottom-0 mb-4 inline-block p-2 rounded-full bg-green-500 text-gray-100 hover:bg-green-700 transition duration-300 mr-4 shadow-raised`}
  svg {
    ${tw`w-16 h-16`}
  }
`;

export default ({
                    features = null,
                    primaryButtonUrl = "#landingPageDemos",
                    primaryButtonText = "Explore Demos",
                    secondaryButtonUrl = "#componentDemos",
                    secondaryButtonText = "View Components",
                    buttonRoundedCss = "",
                    // landingPages = components.landingPages,
                    // innerPages = components.innerPages,
                    // blocks = components.blocks,
                    heading = "Free Modern React Templates for every need.",
                    description = "Easily customizable modern React UI Templates and Components built using TailwindCSS which are also lightweight and simple to setup. All components are modular and fully responsive for great mobile experience as well as big desktop screens.  Brand Colors are also fully customizable. Free for personal as well as commercial use."
                }) => {
    /*
     * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
     * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
     */
    useEffect(() => {
        window.gtag("js", new Date());
        window.gtag("config", "G-T5ZWZT84K4");
    }, [])

    return (
        <AnimationRevealPage disabled>
            <Container tw="-mx-8 -mt-8 pt-8 px-8">
                <Content2Xl>

                    <Hero roundedHeaderButton={true} />

                </Content2Xl>
            </Container>
            <Services
                heading={
                    <>
                        Available <HighlightedText>Services.</HighlightedText>
                    </>
                }
                subheading={"SERVICES"}
                description={"Everything you need to make your life convenient, and more!"} // Feel free to request a service using the form below.
                cards={[
                    {
                        imageSrc: Plumber,
                        title: "Plumbing",
                        description: "Leakage, tap, pipeline fittings or any other needs",
                    },
                    {
                        imageSrc: Cleaner,
                        title: "Cleaning",
                        description: "Room, kitchen top or even a car, we clean them all",
                    },
                    {
                        imageSrc: Electrician,
                        title: "Electrician",
                        description: "Circuit issues, board fittings and everything in between",
                    },
                    {
                        imageSrc: Carpenter,
                        title: "Carpentry",
                        description: "One stop solution for all your carpenting needs",
                    },
                    {
                        imageSrc: Painter,
                        title: "Painting",
                        description: "Skilled painters to make your home give a new look",
                    },
                    {
                        imageSrc: Equipment,
                        title: "Appliances",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    {
                        imageSrc: Fabrication,
                        title: "Fabrication",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    {
                        imageSrc: Gas,
                        title: "Gas Geyser",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    {
                        imageSrc: PestControl,
                        title: "Pest Control",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    {
                        imageSrc: Drainage,
                        title: "Drainage",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    {
                        imageSrc: WaterTank,
                        title: "Water Tank",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                    // {
                    //     imageSrc: Plastering,
                    //     title: "Plastering",
                    //     description: "AC, Refrigerator or even Television, we got it covered",
                    // },
                    {
                        imageSrc: AndMore,
                        title: "And More..",
                        description: "AC, Refrigerator or even Television, we got it covered",
                    },
                ]}

                imageContainerCss={tw`p-2!`}
                imageCss={tw`w-20! h-20!`}
            />

            <Principles
                subheading={<Subheading>WHY US</Subheading>}
                heading={
                    <>
                        Our <HighlightedText>Principles.</HighlightedText>
                    </>
                }
                description={"We strive to provide you with the best in class services, and try to be better, every single day."}
                showDecoratorBlob={false}
                cards={
                [
                    {
                        imageSrc: PricingIcon,  // Dollar sign
                        title: "Transparent Pricing",
                        description: "We always try to get the fair price for any service.",
                    },
                    {
                        imageSrc: VerifiedIcon, // Shield with check mark
                        title: "Verified Partners",
                        description: "We only work with Partners that are verified on our platform.",
                    },
                    {
                        imageSrc: ExpertIcon, // Medal
                        title: "Expert Service",
                        description: "We onboard experts in every field to maintain the quality of service.",
                    },
                    {
                        imageSrc: FlexibleIcon,  // Clock
                        title: "Flexible Timings",
                        description: "We provide flexible timings for all of our services.",
                    },
                    {
                        imageSrc: SupportIcon,  // Headset with person
                        title: "Help & Support",
                        description: "We are always there to help you in everything related to the service.",
                    }
                ]
                }
            />

            {/*<HowItWorks*/}
            {/*    subheading={<Subheading>HOW IT WORKS</Subheading>}*/}
            {/*    heading={*/}
            {/*        <>*/}
            {/*            Simple <HighlightedText>Steps.</HighlightedText>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    textOnLeft={false}*/}
            {/*    imageSrc={steps}*/}
            {/*    imageDecoratorBlob={true}*/}
            {/*    // decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}*/}
            {/*    steps={*/}
            {/*        [*/}
            {/*            {*/}
            {/*                heading: "Call",*/}
            {/*                description: "Call us on +91 9090 1020 38 for any home services that you need."*/}
            {/*            },*/}
            {/*            {*/}
            {/*                heading: "Describe",*/}
            {/*                description: "Let us know what service you are looking for along with your details."*/}
            {/*            },*/}
            {/*            {*/}
            {/*                heading: "Relax",*/}
            {/*                description: "Sit back and let us do the rest, our representative will you call you for the service."*/}
            {/*            //     We make sure to provide the best service representative*/}
            {/*            }*/}
            {/*        ]*/}
            {/*    }*/}
            {/*></HowItWorks>*/}

            {/* <Stats></Stats> */}
            {/*<Team></Team>*/}
            <Testimonials
                heading={
                    <>
                        Customers <HighlightedText>Love Us.</HighlightedText>
                    </>
                }
            ></Testimonials>

            <Faqs
                heading={
                <>
                    Frequently Asked <HighlightedText>Questions.</HighlightedText>
                </>
            }
                description="Have questions about our app or services? Find answers to the most commonly asked questions."
                faqs={
                    [
                        {
                            question: "What services are available on LivEase?",
                            answer:
                                "LivEase is a one-stop platform for all things home services. We offer everything from plumbing, carpentry, painting to bathroom cleaning, pest control just to name a few."
                        },
                        {
                            question: "How can I book a service on LivEase?",
                            answer:
                                "It is simple, you just have to download our app, select a service, a convenient time slot and confirming your booking."
                        },
                        {
                            question: "Do I need to pay to book the service?",
                            answer:
                                "No, booking a service is absolutely FREE. Payment can only be done once the service is completed."
                        },
                        {
                            question: "What if the professional do not show up on time?",
                            answer:
                                "please contact us and we'll do our best to resolve the issue as quickly as possible."
                        },
                        {
                            question: "How do you ensure quality of service on your platform?",
                            answer:
                                "Our partners are only onboarded after they have passed our quality tests and only verified partners can provide service through our platform."
                        },
                        {
                            question: "What if I am not satisfied with the service?",
                            answer:
                                "Please get in touch with us within 24 hours and we will do our best to make it right."
                        },
                    ]
                }
            />
            <DownloadApp
                text={<><HighlightedTextInverse>LivEase</HighlightedTextInverse> - The Home Services SuperApp</>}
            />
            <Footer />
            <SocialLink href="https://api.whatsapp.com/send?phone=+919090102038&text=Hi,+I+am+looking+for+home+service">
                <WhatsAppIcon />
            </SocialLink>
        </AnimationRevealPage>
    );
};
